
.access-cards .card h2 {
  font-size: 1.5rem;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0;
  text-align: left;
}

.access-cards .card {
  margin-bottom: 15px;
}

.access-cards .card .react-bootstrap-table-pagination {
  margin-right: 15px;
}

/* adjust pagination elements */
.access-cards .card .react-bootstrap-table+.row {
  .dropdown-toggle,
  .react-bootstrap-table-page-btns-ul {
    margin-bottom: 0;
  }
}

.access-cards .card .card-header {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
}

.access-cards .card .btn {
  margin-bottom: 1rem;
}

.access-cards .flow-col {
  flex-basis: 28em;
  flex-grow: 1;
}
.access-cards .flow-col-2x {
  flex-basis: 56em;
  flex-grow: 2;
}
