
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";

/* add borders around just the "cells" of the Modal */
.modal-with-borders .modal-body {
  border-top: 1px solid $dark;
  padding: 0;
}
.modal-with-borders .modal-body > .row {
  margin: 0;
}
.modal-with-borders .modal-body > .row > div {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

/* target following columns on viewports greater then the breakpoint size used */
.modal-with-borders--xl .modal-body > .row > div + div {
  border-top: 1px solid $dark;
}
@media (min-width: map-get($grid-breakpoints, lg)) {
  .modal-with-borders--xl .modal-body > .row > div + div {
    border-top: none;
    border-left: 1px solid $dark;
  }
}
