
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.toaster {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;

  .toast {
    transition: 0.2s;
    pointer-events: all;
  }

  button:focus {
    outline: none;
  }
}

@each $color, $value in $theme-colors {
  .toast-#{$color} {
    .toast-header, .close {
      color: darken($value, 28);
      background-color: lighten($value, 37);
      border-color: lighten($value, 33);
    }
    .toast-body {
      background-color: lighten($value, 45);
    }
  }
}
