@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.fluid-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  @include media-breakpoint-up(sm) {
    max-width: max(100%, 540px);
  }
  @include media-breakpoint-up(md) { 
    max-width: max(100%, 720px);
  }
  @include media-breakpoint-up(lg) {
    max-width: max(90%, 960px);
  }
  @include media-breakpoint-up(xl) {
    max-width: max(70%, 1140px);
  }
}