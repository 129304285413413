
/*
 * Add some aspect ratio helpers
 */
.img-aspect-ratio {
  position: relative;
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
}
