
/* take colours from Status Indicator icons (.overall-status-indicator) */
/* these colours are redined in JS as its not possible to do exports here */
$optimal: #26EF0E; // rgba(38, 239, 14, 1)
$warning: #FAF719; // rgba(250, 247, 25, 1)
$danger: #FD2D20; // rgba(253, 45, 32, 1)

.bar-track {
  position: relative;
  display: flex;
  margin: 0px;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 16px;
  height: 16px;
  box-shadow: 0 .125rem .375rem rgba(0, 0, 0, .275); /* darker version of shadow-sm values */

  /* Gradients for all browsers, generated using cssmatic.com */
  background: $optimal;
  background: -moz-linear-gradient(left, $optimal 0%, $warning 50%, $danger 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, $optimal), color-stop(50%, $warning), color-stop(100%, $danger));
  background: -webkit-linear-gradient(left, $optimal 0%, $warning 50%, $danger 100%);
  background: -o-linear-gradient(left, $optimal 0%, $warning 50%, $danger 100%);
  background: -ms-linear-gradient(left, $optimal 0%, $warning 50%, $danger 100%);
  background: linear-gradient(to right, $optimal 0%, $warning 50%, $danger 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{ie-hex-str($optimal)}', endColorstr='#{ie-hex-str($danger)}', GradientType=1 );
}

.bar-thumb {
  margin-left: -2px;
  margin-top: -2px;
  margin-right: -2px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid white;
  box-shadow: 0 0 4px 0.25px rgba(0, 0, 0, .75);
  box-shadow: 0 0 4px 0.25px rgba(0, 0, 0, .75), inset -4px -4px 4px 0 rgba(0, 0, 0, .25);
  z-index: 1;
}

.bar-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: white;
  opacity: 0.8;
  /* blur the edges a little, browser clipping may show colour from underneath */
  box-shadow: 0 0 1px 0 white;
}

/* make bars visible when printing */
@media print {
  .bar-track {
    border: 0.5px solid #aaa;
  }
  .bar-thumb {
    /* the relative positioning is for the print-only ::before and ::after elements */
    position: relative;
    /* adjust for bar-track border */
    margin-left: -2.5px;
    margin-top: -2.5px;
    border: 2.5px solid white;
  }
  .bar-thumb::before, .bar-thumb::after {
    position: absolute;
    content: "◯";
    color: #818181;
    top: -2.5px;
    left: -2.5px;
    font-size: 21px;
    line-height: 21px;
    margin-top: 0.95px;
    margin-left: -0.5px;
  }
  .bar-thumb::after {
    font-size: 16px;
    line-height: 16px;
    margin-top: 3px;
    margin-left: 2px;
  }
}
