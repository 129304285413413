
#temperatureImage {
  width: 35px;
  height: 35px;
}

#toneImage {
  width: 35px;
  height: 35px;
}

#volumeImage {
  width: 35px;
  height: 35px;
}

.eventlist-subtable-row {
  background-color: white;
  cursor: pointer;
}
.eventlist-subtable-row:hover {
  background-color: #F2F2F2;
}