@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";

.usage-table-wrapper {
  padding-left: 15rem;
  padding-right: 15rem;
  
  @media screen and (max-width: map-get($grid-breakpoints, xl)) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  @media screen and (max-width: map-get($grid-breakpoints, sm)) {
    padding-left: 0;
    padding-right: 0;
  }
}