
.mapboxgl-map-container,
.mapboxgl-map {
  position: relative;
  background-color: #c3b792;
  width: 100%;
  height: 100%;
}
.mapboxgl-map-container .mapboxgl-map {
  position: absolute;
}

.mapboxgl-map .mapboxgl-popup-content {
  padding: 0px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.75);
}
.mapboxgl-map .mapboxgl-popup-content .card {
  border: none;
}
.mapboxgl-map .mapboxgl-popup-content .bar-track {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4);
}

.mapboxgl-no-data-indication {
  width: 100%;
}

.mapboxgl-no-data-indication::before {
  content: " ";
  position: absolute;
  background: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.85;
}

/* Make clusters zero-space points for easier math */
.mapboxgl__cluster {
  position: relative;
  height: 0;
  width: 0;
  opacity: 0.9;
}

/* Make clusters element look like equally sized circles */
.mapboxgl__cluster__count {
  position: absolute;
  top: -1rem;
  left: -1rem;
  height: 2rem;
  width: 2rem;
  border-radius: 2rem;
  line-height: 2rem;
  text-align: center;
}

.mapboxgl__cluster__border {
  position: absolute;
  top: -2rem;
  left: -2rem;
  height: 4rem;
  width: 4rem;
}

.mapboxgl__cluster__selected-point {
  opacity: 0.7;
}

.mapboxgl-marker,
.mapboxgl__cluster,
.mapboxgl__cluster__count,
.mapboxgl__cluster__border,
.mapboxgl__cluster__selected-point {
  cursor: pointer;
}