.vertical-line {
  border-left: 3px solid #ccc;
  height: 4.5em;
  margin-left: -8px;
  margin-right: 8px;
  padding: 0;
  top: 0;
}
.vertical-line-transparent {
  @extend .vertical-line;
  border-left: 3px solid transparent;
}

.no-spinners {
  -moz-appearance: textfield;
}

.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
