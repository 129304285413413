
.notification-bar {
  width: 100%;
  width: 100vw;
  border-radius: 0;
  margin: 0;
}

.notification-bar--non-floating {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4; /* above sidebar */
}

.notification-bar--floating {
  visibility: hidden;
}
