.ql-toolbar {
  display: none;
}

.editor {
  width: 100%;
  border-radius: .25rem;
  border-top: 1px solid #ccc !important;
  background: #fff;
}

.editor-mention-list-container {
  width: 500px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgb(30 30 30 / 8%);
  z-index: 9001;
  /* overflow: scroll; */
  max-height: 240px;
  overflow: auto;
}

.editor-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
}