
.overall-status-indicator {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  border: 2.5px solid white;
  box-shadow: 0 0 4px 0.25px rgba(0, 0, 0, .5);
  box-shadow: 0 0 4px 0.25px rgba(0, 0, 0, .5), inset -4px -4px 4px 0 rgba(0, 0, 0, .25);
}
.overall-status-indicator__text {
  background: white;
  border: none;
}

.overall-status-indicator--grey {
  background: #999999;
}
.overall-status-indicator__text--grey {
  color: #999999;
}

.overall-status-indicator--green {
  background: #26EF0E;
}
.overall-status-indicator__text--green {
  color: #26EF0E;
}

.overall-status-indicator--yellow {
  background: #FAF719;
}
.overall-status-indicator__text--yellow {
  color: #FAF719;
}

.overall-status-indicator--red {
  background: #FD2D20;
}
.overall-status-indicator__text--red {
  color: #FD2D20;
}
