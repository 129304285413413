
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";

.account-payment--warning.account-payment__notification-bar {
  background-color: $warning;
  border-color: darken($warning, 6);
  color: darken($warning, 35);
}

.account-payment--danger.account-payment__notification-bar,
.account-payment--danger.account-payment__modal .modal-content {
  background-color: $danger;
  border-color: darken($danger, 15);
  color: white;
}

.account-payment {
  a.learn-more {
    text-decoration: underline;
  }
}

.account-payment--danger {
  a.learn-more {
    color: lighten($danger, 30);
  }
}

.account-payment--danger.account-payment__modal {
  background-color: rgba($danger, 0.1);
}