
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";

.beta-banner--danger.beta-banner__notification-bar {
  background-color: white;
  border-color: $danger;
  color: black;
}

.beta-banner {
  a.click-here {
    text-decoration: underline;
  }
}
