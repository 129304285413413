
/* allow image padding to be relative to the link */
.store-badges__app-store,
.store-badges__google-play {
  display: inline-block;
  margin: 2px;

  /* set image sizes */
  img {
    width: 160px;
    max-width: 100%;
  }
}

/* if the badges are to be inline, then make the buttons the same height */
.store-badges--inline {
  .store-badges__app-store {
    img {
      width: 142px;
    }
  }
}
