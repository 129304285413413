
.square-button-card {
  position: relative;
  padding-bottom: 100%;
}

.square-button-card .btn {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.image-upload__img-toolbar .btn,
.square-button-card .btn {
  cursor: pointer;
}

.image-upload__img-container {
  position: relative;
}

.image-upload__img-toolbar {
  position: absolute;
  top: 4px;
  right: 4px;
  text-align: right;
}
.image-upload__img-toolbar .btn {
  padding: 0.25rem 0.3rem;
}

.image-upload__add-image {
  line-height: 2;
}

.image-upload__add-image svg {
  vertical-align: -17.5%;
}

/* style multiple images under one images-upload container */
.image-upload__images-container {
  /* they should have spacing and wrap inside the container */
  .image-upload__img-container, .image-upload__add-button-container {
    margin-top: 10px;
    margin-right: 10px;
    display: inline-block;
  }
  /* make sure the last element doesn't have space to the right */
  .image-upload__img-container:last-child, .image-upload__add-button-container:last-child {
    margin-right: 0;
  }
  /* they should take up as little space as needed, but also have a reasonable max */
  .image-upload__img {
    max-width: 450px;
    max-height: 150px;
    width: auto;
    height: auto;
  }
  /* set add button at maximum height and square */
  .image-upload__add-button {
    height: 150px;
    width: 150px;
  }
}
