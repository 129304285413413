
.activity-card .card-title {
  margin-bottom: 1.5rem;
}

.activity-card .card-title img {
  width: 2.2em;
  margin-right: 4px;
}

.activity-card .subtitle {
  font-size: 70%;
  font-weight: normal;
  margin-top: 0.25rem;
  margin-top: 0.25rem;
}
