@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";

.nc__dropdown {
  
  svg.nc__delete-icon {
    vertical-align: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px
  }
  
  .dropdown-toggle {
    
    &::after {
      display: none !important; // Remove the caret from dropdown.
    }
  }
  
  .nc__unread-count {
    position: absolute;
    right: -3px;
    top: 0;
  }
  
  .dropdown-menu.show {
    overflow: auto;
    max-height: 80vh;
  }
  
  .nc__dropdown-item {
    
    &:active {
      background-color: #fafafa;
      color: #333;
    }
    
    .nc__notification-text {
      width: 380px;
      cursor: pointer;
      
      @media screen and (max-width: map-get($grid-breakpoints, sm)) {
        width: 220px;
      }
    }
    
    .nc__no-notification-text {
      width: 400px;
      cursor: pointer;
      
      @media screen and (max-width: map-get($grid-breakpoints, sm)) {
        width: 240px;
      }
    }
    
    &:last-child .nc__meta-data {
      border-style: none !important;
    }
    
    & a {
      color: #212529;
      &:hover {
        text-decoration: none;
      }
    }
    
    .nc__read-circle {
      cursor: pointer;
      width: 15px;
      height: 15px;
      display: inline-block;
      border-radius: 50%;
      transform: translateY(2px);
      
      &.danger.nc__read {
        border: 2px solid rgb(198, 9, 59);
      }
      
      &.danger.nc__unread {
        background: rgb(198, 9, 59);
      }
      
      &.normal.nc__read {
        border: 2px solid rgb(0, 175,170);
      }
      
      &.normal.nc__unread {
        background: rgb(0, 175,170);
      }
    }
    
    .nc__timestamp {
      font-size: 12px;
      color: #888;
      height: 24px;
    }
    
    button.nc__button-delete:hover {
      background-color: transparent;
    }
  }
  
}