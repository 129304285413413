.equipment-list-edit-columns-modal {
  .modal-body {
    padding-top: 0;
  }
}

.draggable-item {
  border: 1px solid #fff;

  .drag-icon {
    align-self: stretch;
    display: flex;
    align-items: center;
    cursor: grab;
  }

  .drag-icon:active {
    cursor: grabbing;
  }

  .drag-icon path {
    stroke: rgb(170, 170, 170);
  }
}

.draggable-hovered {
  background-color: #eee;
}

.draggable-targeted {
  border: 1px solid #ccc;
}