@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";

a.bill__nav-button {
  @media screen and (max-width: map-get($grid-breakpoints, sm)) {
    font-size: 1rem;
  }
  
  @media screen and (max-width: 460px) {
    font-size: .9rem;
    padding: .2rem .5rem;
  } 
}