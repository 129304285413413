
#footer {
  width: 100%;
  background-color: #f8f9fa;
}

.footer__copyright {
  white-space: nowrap;
}

/* current logo is aspect ratio: 101.15 x 20.04 */
.footer__copyright img {
  height: 1.1em;
  width: 5.55em; /* specify width explicitly for IE */
  margin-top: -4px;
}

.footer--floating {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: inherit;
}

/* Do not print the floating footer */
@media print {
  .footer--floating {
    display: none;
  }
  .footer--non-floating.invisible {
    display: block !important;
    visibility: visible !important;
  }
}
