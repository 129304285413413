
/*
 * Add a wall to preven
 */
.app-blocker {
  background-color: #ffffff;
  opacity: 0.7;
  position: fixed;
  z-index: 1000000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
