
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";

.profile-page__navbar-row {
  border: 1px solid #e7e7e7;
}

/* allow this nav bar to be displayed when printing */
@media print {
  .profile-page__navbar-row {
    display: block;
  }
}

.profile-page__header .img-thumbnail {
  width: 160px;
  height: 160px;
  position: absolute;
  margin-top: 20px;
  z-index: 1;
}

/* profile image files */
.profile-page__header img.img-thumbnail {
  cursor: pointer;
}

.profile-page__header .img-thumbnail .image-upload__img-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 15px;
}

.profile-page__header .img-thumbnail .image-upload__img {
  max-width: 100%;
  max-height: 100%;
}

.profile-page__header .img-thumbnail .image-upload__add-button-container,
.profile-page__header .img-thumbnail .image-upload__add-button {
  margin: 0;
  width: 100%;
  height: 100%;
}
.profile-page__header .img-thumbnail .image-upload__add-button-container .file-upload__feedback {
  position: absolute;
  left: 5px;
  bottom: 5px;
  margin: 0;
  width: auto;
}

.profile-page__sidebar {
  margin-top: 10px;
}
.profile-page__navbar {
  min-height: 2.625em; /* usual height of the navbar */
}

@media (min-width: map-get($grid-breakpoints, lg)) {
  .profile-page__header .img-thumbnail {
    width: 100%;
    height: 255px;
  }

  .profile-page__sidebar {
    margin-top: 25px;
  }
}

.profile-page__header {
  margin: 0px!important;
  background-color: #ccc;
  background-image: url("../images/deviceDetailsPattern.png");
  background-repeat: repeat;
  min-height: 200px;
}
