/*
* Ensure charts can be printed without overflowing their containers
*/
.echarts-for-react {
  max-width: 100%;

  div,
  canvas {
    max-width: 100%;
  }
}

div .DateInput_input {
  /* based on Bootstrap btn-lg font-size: 1.25rem and line-height: 1.5 */
  line-height: 1.875;
  height: 2.875rem;
  /* based on Bootstrap btn-lg padding: 0.5rem 1rem (with space for border) */
  padding: 0.5rem 0rem 0.375rem;
  border-bottom-width: 0.125rem;
}

.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: normal
}