
/* vertically align icons to text inside buttons */
.alarm-sound-button {
  .spinner {
    vertical-align: -20%;
  }
  .bp3-icon {
    vertical-align: -20%;
  }
}
