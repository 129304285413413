
.modal-header, .modal-footer {
  border: none;
}

.modal-header button:focus {
  outline: none;
}

.modal-md {
  max-width: 530px;
}
