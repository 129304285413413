
/* adjust the default title headings to use the toolbar margin-bottom instead of its own */
.table-toolbar .title h2 {
  margin-bottom: 0;
}

/* adjust bootstrap input elements baseline alignment to appear well with the current toolbar */
.table-toolbar__inputs {
  display: inline-block;
  vertical-align: 0.5rem;
}

/* react-ion-icons fix */
.table-toolbar__inputs svg {
  vertical-align: -0.35em;
}

/* the search bar library has a search-label class that reset.css adds a margin to :/ */
.table-toolbar .search-label {
  margin-bottom: 0;
}
