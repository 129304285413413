
/*
 * for more advanced themeing use react-with-styles
 * https://github.com/airbnb/react-dates#theming
 */

/* add div to add specificity */
div .DateInput_input {
  font-size: 1rem; /* make consistent with the rest of the forms */
  text-align: center; /* looks better on date range picker */
}

div .DateInput {
  width: 7.5rem; /* make the input box smaller as the font is smaller */
}

/* make input field look more like bootstrap when in a form */
form .SingleDatePickerInput,
form .DateRangePickerInput {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
form .SingleDatePickerInput_calendarIcon,
form .DateRangePickerInput_calendarIcon {
  padding: 0 4px 6px; /* line up the icon with the text a little better */
}
form .DateInput_input {
  padding: 6px 11px 4px; /* make text padding closer to Bootstrap */
  background-color: transparent;
}
form .DateInput {
  background-color: transparent;
}
