
/* Allow table to scroll horizontally and not mess with mobile view widths */
@media screen {
  .react-bootstrap-table {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden; /* explicity specify so Webkit doesn't add a vertical scrollbar */
  }

  .row-expansion-style .react-bootstrap-table {
    overflow-x: hidden;
  }
}
.react-bootstrap-table table {
  table-layout: auto;
  /* fix scroll-bars being far from table content */
  margin-bottom: 0;
}

.react-bootstrap-table table.table-fixed-width {
  table-layout: fixed;
  /* fix scroll-bars being far from table content */
  margin-bottom: 0;
  // min-width: 1200px;
}

.react-bootstrap-table table.min-width-1200 {
  min-width: 1200px;
}

.react-bootstrap-table table.min-width-500 {
  min-width: 500px;
}

@media (min-width: 576px) {
  .react-bootstrap-table2-search-header {
    width: 200px;
  }
}

/* align icons inside table cells */
table tr td svg {
  vertical-align: -25%;
}

/* align icons inside table buttons */
table tr .btn svg {
  vertical-align: -17%;
}

/* ensure table buttons don't put icons and text on multiple lines. tested on IE11 and Safari */
table tr .btn {
  white-space: nowrap;
}

// CSS for expanded table.
.react-bootstrap-table .row-expansion-style {
  padding: 0
}

.row-expansion-style .react-bootstrap-table .header-class {
  display: none;
}

.expanded-area {
  border-bottom: 1px solid #888;
}

.equipment-list-table .react-bootstrap-table table {
  thead th {
    vertical-align: top;
  }  
}

table th label.filter-label {
  display: block;
  margin-bottom: 0;
}

table .status-filter {
  margin-top: 3px;
  min-width: 100px;
  font-size: 14px;
  padding: 0 .2rem;
  height: 30px;
}

.smaller-font-size-table-container .react-bootstrap-table table {
  font-size: 95%
}

.row-expansion-style .react-bootstrap-table .no-header {
  display: none;
}

.sub-table-background {
  background-color: #f5f5f5;
}