/* main layout and style */

body {
  /* do not set `overflow-y: scroll` on `html` tag: */
  /* that will cause body scrolling underneath modal dialogs. */
  /* overflow-y here will cause a vertical scrollbar to be always present */
  /* which helps prevent layout changes due to changing available body width */
  overflow-y: scroll;
  background-color: #f8f9fa;
  font-family: 'Avenir', sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #007bff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
}

/* add fixes for IE10+ */
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {

  /* Put your IE-only styles here. Works for IE 10 & IE 11*/
  html,
  body,
  #root {
    height: 100%;
    /* fix IE flexbox height issues */
  }
}

#root,
#app,
#main {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

/* set basis that the root elements are all parents, of cascading z-index */
#main,
.notification-bar,
#header,
#body,
#footer,
#sidebar {
  position: relative;
  /* note: flex-shrink:0 is extremely important for IE10 and 11 which defaults to 1? */
  /* note: flex-basis:auto is extremely important for IE10 and 11 which defaults to flex-basis:0 */
  flex: 0 0 auto;
}

#sidebar {
  z-index: 3;
}

#header {
  position: sticky;
  top: 0;
  background-color: #f8f9fa;
  z-index: 2;
}

#body {
  z-index: 1;
  /* allow body to take up as much space as it can in the window */
  flex-grow: 1;
  /* allow children of body to size themselves with flex */
  display: flex;
  flex-direction: column;
}

#footer {
  /* Make sure the footer is not below the body, so the footer may be fixed (float) over it */
  z-index: 1;
}

/* general fade-in style */
.fade-in {
  animation: fadeIn ease 0.2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* loading spinner */

.spinner {
  pointer-events: none;
  width: 4em;
  height: 4em;
  border: 0.5em solid transparent;
  border-color: #dee2e6;
  border-top-color: #0b7975;
  border-radius: 50%;
  animation: loadingSpin 1s linear infinite;
  vertical-align: -12.5%;
}

/* add a delay when fading in a spinner, to handle very short loading states */
.spinner.fade-in {
  opacity: 0;
  animation: loadingSpin 1s linear infinite, fadeIn ease 0.2s 0.1s;
  animation-fill-mode: forwards;
  /* preserve opacity: 1; on end */
}

#root>.spinner {
  margin: auto;
}

@keyframes loadingSpin {
  100% {
    transform: rotate(360deg);
  }
}