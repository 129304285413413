
.info-card {
  .card-header {
    /* vertically align header text */
    align-items: center;

    /* ensure buttons appear as a horizontal row */
    .info-card__header-buttons {
      display: flex;
      flex-direction: row;
      /* with a decent margin from the header */
      margin-left: 0.3em;
    }
  }
  .info-card__inline-heading-button {
    vertical-align: 15%;
  }
}

.info-card.info-card--collapsible {
  .card-header {
    .info-card__dropdown {
      margin-left: 0.3em;
    }
  }
}
