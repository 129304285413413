
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";

.indicator-badge.badge {
  vertical-align: 0.1em;
  font-size: 100%; /* normal badges are scaled down */
}

.indicator-badge.badge {
  padding: 1.75px 2px 2.25px;
  margin-right: 2px;
}

/* modify the warning style a little */
.indicator-badge.badge-warning {
  color: white;
}
.indicator-badge.badge-warning {
  background-color: darken(map-get($theme-colors, warning), 8);
}

// add a bit more space around expanded text
.indicator-badge.badge--expanded {
  padding-left: 3px;
  padding-right: 3px;
}

.indicator-badge span {
  font-size: 0.8em;
  margin-left: 0.25em;
  margin-right: 1px;
  vertical-align: middle;
}

.indicator-badge .indicator-direction {
  font-size: 1.4em;
  margin-left: -0.3em;
  margin-right: -0.3em;
}
