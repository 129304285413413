
@import url('https://fonts.googleapis.com/css?family=Montserrat:700');
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";

.root-page-centered h1 {
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
}

.root-page-centered {
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
}

.root-page-centered__row {
  margin: 15px;
  width: 100%;
}

.root-page-centered__column {
  width: 100%;
  max-width: 360px;
  max-width: 22.5rem;
  margin: auto;
}

/* floating labels for unauthenticated forms */
.form-floating-labels {

  .form-group {
    position: relative;
  }

  .form-control {
    position: relative;
  }

  .form-control:focus {
    z-index: 2;
  }

  label {
    z-index: 3;
  }

  .form-group > input,
  .form-group > label {
    height: 3.125rem;
    padding: .75rem;
  }

  .form-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0; /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    pointer-events: none;
    cursor: text; /* Match the input under the label */
    border: 1px solid transparent;
    transition: all .1s ease-in-out;
  }

  .form-group input::-webkit-input-placeholder { color: transparent; }
  .form-group input:-ms-input-placeholder { color: transparent; }
  .form-group input::-ms-input-placeholder { color: transparent; }
  .form-group input::-moz-placeholder { color: transparent; }
  .form-group input::placeholder { color: transparent; }

  .form-group input:not(:placeholder-shown) {
    padding-top: 1.25rem;
    padding-bottom: .25rem;
  }

  .form-group input:not(:placeholder-shown) + label {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 12px;
    color: #777;
  }
  /* style Chrome autofilled inputs the same as regularly filled inputs
   * place this selector on a different line than the previous class
   * as it invalidates the whole rule on Firefox,  */
  .form-group input:-webkit-autofill + label {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 12px;
    color: #777;
  }

  /* Fallback for Edge
  -------------------------------------------------- */
  @supports (-ms-ime-align: auto) {
    .form-group > label {
      display: none;
    }
    .form-group input::-ms-input-placeholder {
      color: #777;
    }
  }

  /* Fallback for IE
  -------------------------------------------------- */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .form-group > label {
      display: none;
    }
    .form-group input:-ms-input-placeholder {
      color: #777;
    }
  }

  /* floating labels grouped */

  .form-group {
    margin-bottom: 0;
  }

  .form-group {
    input {
      margin-bottom: -1px;
      border-radius: 0;
    }
    &:first-of-type {
      input {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }
    }
    &:last-of-type {
      input {
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        margin-bottom: 10px;
      }
    }
  }

  /* center the spinner when inside the loading buttons */
  .spinner {
    margin: 0 auto;
  }

}
